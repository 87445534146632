var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ebaRegistrationStep",
        { ref: "RegistrationStep", attrs: { "step-title": "Communications." } },
        [
          _c("div", { staticClass: "registrationText" }, [
            _vm._v(
              "\n            By signing up to Experian business marketing communications you will receive the latest research, insight, news and invites \n            to events and webinars. Experian offers a wide range of products and services. We will do our best to send you only communications \n            that we deem to be relevant to you, your job and your business. You can change your mind at any time.\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "registrationText" }, [
            _vm._v("\n            Would you like to hear from us?\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "registrationInput" },
            [
              _c(
                "ebaCheckbox",
                {
                  ref: "communicationInput",
                  attrs: { noValidation: true, inputID: "communicationsInput" },
                  model: {
                    value: _vm.registrationData.MarketingOptIn,
                    callback: function ($$v) {
                      _vm.$set(_vm.registrationData, "MarketingOptIn", $$v)
                    },
                    expression: "registrationData.MarketingOptIn",
                  },
                },
                [
                  _vm._v(
                    "\n                I would like to receive Experian marketing communications\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }