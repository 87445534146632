<template>
    <div>
        <ebaRegistrationStep ref="RegistrationStep" step-title="Communications.">
            <div class="registrationText">
                By signing up to Experian business marketing communications you will receive the latest research, insight, news and invites 
                to events and webinars. Experian offers a wide range of products and services. We will do our best to send you only communications 
                that we deem to be relevant to you, your job and your business. You can change your mind at any time.
            </div>
            <div class="registrationText">
                Would you like to hear from us?
            </div>
            <div class="registrationInput">
                <ebaCheckbox ref="communicationInput"
                             :noValidation="true"
                             v-model="registrationData.MarketingOptIn"
                             inputID="communicationsInput">
                    I would like to receive Experian marketing communications
                </ebaCheckbox>
            </div>
            <br/>
            <br/>
        </ebaRegistrationStep>
    </div>
</template>

<script>

    import {get as config} from '../../scripts/common/config';

    export default {
        name: 'CommsAndMarketing',
        props: {
            registrationData: {
                type: Object
            }
        },
        mounted() {
            this.$emit('clearHeaderText');
            this.$emit('clearCustomProceedText');
        },
        data() {
            return {
                thirdPartyServices: config().ThirdPartyServices
            };
        },
        methods: {
            isStepValid: function () {
                var self = this;

                return new Promise(function (resolve, reject) {
                    var isValid = true;

                    if (!self.$refs.communicationInput.validate()) {
                        isValid = false;
                    }

                    resolve(isValid);
                });
            }
        }
    };
</script>

<style lang="less" scoped>

    @import '../../components/common/assets/css/common.less';


</style>
